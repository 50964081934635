<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col
                  cols="12"
                  :sm="header.sm"
                  :md="header.md"
                  v-for="(header, columnIndex) in selection"
                  :key="columnIndex"
                >
                  <cursorselect
                    v-if="header.type == 'cur_product'"
                    :Qsearch="products_cursor"
                    :Qresp="'products_cursor'"
                    :value="editedItem[header.value]"
                    v-model="editedItem[header.value]"
                    :text_fields="['code', 'label']"
                    :variableadd="header.variableadd"
                    :whereadd="header.whereadd"
                    :label="'Produit'"
                    @change="produit_change"
                    :key="cs"
                  >
                  </cursorselect>
                  <cursorselect
                    v-if="header.type == 'cur_tier'"
                    :Qsearch="tiers_cursor"
                    :Qresp="'tiers_cursor'"
                    :value="editedItem[header.value]"
                    v-model="editedItem[header.value]"
                    :text_fields="['code_client', 'code_fournisseur', 'nom']"
                    :variableadd="header.variableadd"
                    :whereadd="header.whereadd"
                    :label="header.text"
                    @change="tierChange"
                    :key="cs"
                  >
                  </cursorselect>
                  <v-text-field
                    autocomplete="off"
                    ref="qte23"
                    v-if="header.type == 'qte'"
                    v-model.number="editedItem[header.value]"
                    :label="header.text"
                    :rules="[
                      (v) =>
                        !!v || header.oblig == false || 'Quantité obligatoire',
                      (v) =>
                        v <= item.qte_max ||
                        item.qte_max == null ||
                        'Quantité Max: ' + item.qte_max,
                      (v) =>
                        v >= item.qte_min ||
                        item.qte_min == null ||
                        'Quantité Min: ' + item.qte_min,
                    ]"
                    :readonly="header.readonly || (!Update && item.id > 0)"
                    type="number"
                    :dense="header.dense"
                    hide-spin-buttons
                    autofocus
                    :disabled="add_disable || save_disable"
                    @keydown.enter="save"
                    @focus="$event.target.select()"
                  ></v-text-field>
                  <v-text-field
                    autocomplete="off"
                    v-if="header.type == 'number'"
                    v-model.number="editedItem[header.value]"
                    :label="header.text"
                    :rules="header.rules"
                    :hint="header.hint"
                    :readonly="header.readonly || (!Update && item.id > 0)"
                    type="number"
                    :dense="header.dense"
                    autofocus
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                  <v-text-field
                    autocomplete="off"
                    v-if="header.type == 'text'"
                    v-model="editedItem[header.value]"
                    :label="header.text"
                    :rules="header.rules"
                    :hint="header.hint"
                    :readonly="header.readonly || (!Update && item.id > 0)"
                    autofocus
                    :dense="header.dense"
                    @focus="$event.target.select()"
                  ></v-text-field>
                  <v-textarea
                    v-if="header.type == 'area'"
                    v-model="editedItem[header.value]"
                    :label="header.text"
                    :rules="header.rules"
                    :readonly="header.readonly || (!Update && item.id > 0)"
                    :hint="header.hint"
                    :dense="header.dense"
                  ></v-textarea>
                  <v-text-field
                    autocomplete="off"
                    v-if="header.type == 'color'"
                    v-model="color"
                    label="Couleur"
                    hide-details
                    solo
                    class="ma-0 pa-0"
                    :hint="header.hint"
                    :dense="header.dense"
                    :readonly="header.readonly || (!Update && item.id > 0)"
                  >
                    <template v-slot:append>
                      <v-menu
                        v-model="menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text
                            class="pa-0"
                            v-if="Update || item.id < 0 || !header.readonly"
                          >
                            <v-color-picker
                              v-model="color"
                              flat
                              :disabled="
                                header.readonly || (!Update && item.id > 0)
                              "
                              @input="colinput"
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>

                  <v-select
                    v-else-if="header.type == 'select'"
                    v-model="editedItem[header.value]"
                    :items="header.select_list"
                    :item-text="header.item_text"
                    :item-value="header.item_value"
                    :label="header.text"
                    :rules="header.rules"
                    :chips="header.chips"
                    :readonly="header.readonly || (!Update && item.id > 0)"
                    :dense="header.dense"
                    :multiple="header.multiple"
                    :hint="header.hint"
                  ></v-select>
                  <selecttree
                    v-else-if="header.type == 'selecttree'"
                    :items="header.select_list"
                    v-model="parent"
                    :value="parent"
                    :label="header.text"
                    :item_disabled="'locked'"
                    :rules="header.rules"
                    :clearable="editedItem.fk_parent != 0"
                    :deletable_chips="false"
                    :readonly="header.readonly || (!Update && item.id > 0)"
                    :select_type="'leaf'"
                    :selectable="false"
                  >
                  </selecttree>
                  <v-checkbox
                    v-else-if="header.type == 'check-box'"
                    v-model="editedItem[header.value]"
                    :label="header.text"
                    :readonly="header.readonly || (!Update && item.id > 0)"
                  ></v-checkbox>
                  <v-autocomplete
                    v-else-if="header.type == 'autocomplete'"
                    v-model="editedItem[header.value]"
                    :value="editedItem[header.value]"
                    :items="header.select_list"
                    :item-value="header.item_value"
                    :label="header.text"
                    :filter="customFilter"
                    :rules="header.rules"
                    single-line
                    :readonly="header.readonly || (!Update && item.id > 0)"
                    :dense="header.dense"
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{ item[header.item_text] }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item[header.item_text] }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="header.subtitle1">
                          {{
                            header.subtitle1_label +
                            " " +
                            item[header.subtitle1]
                          }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-if="header.subtitle2">
                          {{
                            header.subtitle2_label +
                            " " +
                            item[header.subtitle2]
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="header.subtitle3">
                          {{
                            header.subtitle3_label +
                            " " +
                            item[header.subtitle3]
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-else-if="header.type == 'phone'"
                    outlined
                    dense
                    v-model="editedItem[header.value]"
                    :rules="[(v) => !!v || 'Numero obligatoire']"
                    :label="header.text"
                    :readonly="header.readonly || (!Update && item.id > 0)"
                    @focus="$event.target.select()"
                  ></v-text-field>
                  <datepicker
                    v-else-if="header.type == 'date'"
                    :label="header.text"
                    v-model="editedItem[header.value]"
                    :value="editedItem[header.value]"
                    :edit="Update"
                    :key="kdp"
                    :date_max="header.date_max"
                    :dense="header.dense"
                    :rules="header.rules"
                  ></datepicker>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
              v-if="Update || item.id < 0"
              :disabled="add_disable || save_disable"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar
          v-model="snackbar"
          :timeout="snackbar_timeout"
          top
          :color="snackbar_color"
        >
          {{ snackbar_text }}
        </v-snackbar>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
import PRODUCTS_CURSOR from "../graphql/Mouvment/PRODUCTS_CURSOR.gql";
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
export default {
  name: "formstd",
  components: {
    datepicker: () => import("./DatePicker.vue"),
    selecttree: () => import("./SelectTree.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
  },
  props: {
    item: Object,
    headers: Array,
    items: Array,
    title: String,
    qCreate: Object,
    qUpdate: Object,
    Get_suf: String,
    Update: { type: Boolean, default: true },
    push: { type: Boolean, default: false },
    showForm: Boolean,
  },

  data: () => ({
    add_disable: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {},
    editedItem: {},
    color: "",
    save_disable: false,
    mask: "!#XXXXXXXX",
    menu: false,
    tree: [],
    parent: [],
    kdp: 100,
    cs: 200,
    filter_array: [],
  }),

  computed: {
    products_cursor() {
      return PRODUCTS_CURSOR;
    },
    tiers_cursor() {
      return TIERS_CURSOR;
    },
    swatchStyle() {
      return {
        backgroundColor: this.color,
        border: this.color ? "" : "solid",
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    selection() {
      return this.headers.filter((item) => {
        if (item.edit === true) {
          return item;
        }
      });
    },

    formTitle() {
      return this.item
        ? (this.item.id < 0 ? "Ajout " : "Modifier ") +
            (this.title ? this.title : "")
        : "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      this.kdp++;
      if (this.editedItem.fk_parent != 0) {
        this.parent.push(this.editedItem["fk_parent"]);
      }
      this.filter_array = [];
      this.headers
        .filter((elm) => elm.type == "autocomplete")
        .forEach((element) => {
          this.filter_array.push(element.item_text);
          if (element.subtitle1) this.filter_array.push(element.subtitle1);
          if (element.subtitle2) this.filter_array.push(element.subtitle2);
          if (element.subtitle3) this.filter_array.push(element.subtitle3);
        });
      this.selection.forEach((elm) => {
        if (elm.type == "tree-select") {
          if (this.editedItem[elm.value] != 0) {
            this.tree.push(
              this.items.find((elm) => elm.id == this.editedItem["fk_parent"])
            );
          }
        }
        if (elm.type == "color") {
          this.color = this.editedItem[elm.value];
        }
        if (elm.type == "check-box") {
          this.editedItem[elm.value] = this.editedItem[elm.value] == "1";
        }

        if (elm.type == "select") {
          if (elm.multiple)
            this.editedItem[elm.value] = this.editedItem[elm.value]
              ? this.editedItem[elm.value].split(",")
              : [];
        }
      });
    }
    if (this.item.id < 0) {
      this.selection.forEach((elm) => {
        if (elm.default) {
          this.editedItem[elm.value] = elm.default;
          if (elm.type == "color") this.color = elm.default;
        }
      });
    }
  },

  methods: {
    produit_change(item, l) {
      let i;
      let list = l;
      if (item) {
        if (list.length > 0) {
          i = list.findIndex((elm) => elm.id == item);
          if (i >= 0) {
            let j = this.selection.findIndex(
              (elm) => elm.type == "cur_product"
            );
            if (j >= 0) {
              this.editedItem[this.selection[j].value] = list[i].articles[0].id;
              this.editedItem[this.selection[j].label] = list[i].label;
              this.editedItem[this.selection[j].code] =
                list[i].articles[0].code;
            }

            //if (this.newproduct) this.editedItem.label = list[i].label;
          }
        } else this.units_list = this.units;
      }
    },
    tierChange(item, l) {
      let i;
      let list = l;
      if (item) {
        if (list.length > 0) {
          i = list.findIndex((elm) => elm.id == item);
          if (i >= 0) {
            let j = this.selection.findIndex((elm) => elm.type == "cur_tier");
            if (j >= 0) {
              this.editedItem[this.selection[j].value] = list[i].id;
              this.editedItem[this.selection[j].label] = list[i].nom;
              this.editedItem[this.selection[j].code] = list[i].code_fournisseur
                ? list[i].code_fournisseur
                : list[i].code_client;
            }
          }
        }
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      this.filter_array.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    colinput() {
      if (typeof this.color === "object") this.color = this.color.hexa;
    },
    close() {
      this.$emit("close");
    },
    async save() {
      let variables = {};
      let s = {};

      this.editedItem.fk_parent =
        this.parent.length > 0 ? this.parent.toString() : "0";
      this.selection.forEach((elm) => {
        if (elm.type == "select" || elm.type == "autocomplete") {
          this.editedItem[elm.value] = this.editedItem[elm.value]
            ? this.editedItem[elm.value].toString()
            : null;
          s[elm.value] = this.editedItem[elm.value];
        } else if (elm.type == "color") {
          s[elm.value] = this.color;
          this.editedItem[elm.value] = this.color;
          s[elm.value] = this.editedItem[elm.value];
        }
        if (elm.type == "number" && elm.type_base == "string") {
          s[elm.value] = this.editedItem[elm.value].toString();
        } else if (elm.type == "check-box") {
          if (this.editedItem[elm.value]) s[elm.value] = 1;
          else s[elm.value] = 0;
        } else {
          s[elm.value] = this.editedItem[elm.value];
        }
      });
      s["write_uid"] = this.$store.state.me.id;
      if (this.$refs.form.validate()) {
        this.add_disable = true;
        this.save_disable = true;
        this.progress = true;

        if (this.item.id > 0) {
          s["id"] = this.editedItem.id;
          if (this.qUpdate) {
            variables[this.Get_suf.toLowerCase()] = s;
            await this.$apollo
              .mutate({
                mutation: this.qUpdate,
                variables,
              })
              .then(() => {})
              .catch((error) => {
                this.loading = false;
                this.snackbar_text = error.message;
                this.snackbar_color = "error";
                this.snackbar = true;
              });
          }
          this.items.splice(
            this.items.findIndex((e) => e.id == this.editedItem.id),
            1,
            this.editedItem
          );
          this.progress = false;
          this.$emit("change", this.editedItem);
          this.$emit("close", this.editedItem);
        } else {
          s["create_uid"] = this.$store.state.me.id;
          if (this.qCreate) {
            variables[this.Get_suf.toLowerCase()] = s;

            await this.$apollo
              .mutate({
                mutation: this.qCreate,
                variables,
              })
              .then((data) => {
                this.progress = false;
                this.editedItem.id =
                  data.data["create".concat(this.Get_suf)].id;
                if (this.push) this.items.push(this.editedItem);
                else this.items.unshift(this.editedItem);
                this.$emit("close", this.editedItem);
                //this.$store.dispatch("Changed", true);
                this.$emit("add", this.editedItem);
              })
              .catch((error) => {
                this.loading = false;
                this.snackbar_color = "error";
                this.snackbar_text = error.message;
                this.snackbar = true;
              });
          } else {
            this.editedItem.id = this.items.length + 1;
            s["id"] = this.editedItem.id;
            if (this.push) this.items.push(s);
            else this.items.unshift(s);
            this.$emit("close", s);
            //this.$store.dispatch("Changed", true);
            this.$emit("add", s);
          }
        }
        this.add_disable = false;
        this.save_disable = false;
      }
    },
  },
};
</script>
